/*
 * @Date: 2023-02-24 10:07:14
 * @LastEditTime: 2023-07-19 16:19:51
 */
import axios from "axios";
import { useUserStore } from "@/store/index";
import { message } from "ant-design-vue";
import { Modal } from "ant-design-vue";
import router from "@/router";

const request = axios.create({
  baseURL: "/api",
  timeout: 60000,
  headers: {},
});

// 异常拦截处理器
const errorHandler = (error) => {
  return Promise.reject(error);
};

request.interceptors.request.use((config) => {
  const counter = useUserStore();
  config.headers.Authorization = counter.token;
  return config;
}, errorHandler);

request.interceptors.response.use((response) => {
  const { config, data } = response;
   if (/^\/v1\/tasks/.test(config.url)) {
     return data;
   }
  if (config.url === "/v1/services/aigc/text2image/image-synthesis") {
    return data;
  }
  switch (response.data.resultCode) {
    case 200:
      return response.data;
    case 2001:
    case 2007:
    case 2008:
    case 2009:
      message.warning(response.data.resultMsg, 2);
      router.push({ path: "/user/login" });
      break;
    case 2006:
      router.push("/");
      Modal.error({
        title: "警告",
        content: "您已在别的窗口打开，请留意...",
        class: "Modal",
        onOk: () => {
          sessionStorage.clear();
        },
      });
      break;
    default:
      !["/file/uploadImage", "/stu-log/updateLog"].includes(
        response.config.url
      ) && message.warning(response.data.resultMsg, 2);
      return response.data.success;
  }
}, errorHandler);

export { request };
