/*
 * @Date: 2022-08-16 09:31:08
 * @LastEditTime: 2023-07-07 11:35:05
 */
import { useUserStore, useCommonStore, useChatStore } from '@/store/index';

// 刷新页面后设置缓存
const Initializer = () => {
  const userStore = useUserStore(),
    commonStore = useCommonStore(),
    chatStore = useChatStore();

  userStore.SET_TOKEN(sessionStorage.getItem('token'));
  userStore.SET_NAME(sessionStorage.getItem('name'));
  userStore.SET_PHONE(sessionStorage.getItem('phone'));
  userStore.SET_RACEID(sessionStorage.getItem('raceId'));
  userStore.SET_STUID(sessionStorage.getItem('stuId'));
  chatStore.set_stuId(sessionStorage.getItem("stuId"));
  chatStore.set_raceId(sessionStorage.getItem("raceId"));

  commonStore.SET_COMMESS(JSON.parse(sessionStorage.getItem('comMess')));
  commonStore.SET_LOGID(sessionStorage.getItem('logId'));
};

// 设置为不输出日志（上线使用）
const rewirteLog = () => {
  console.log = (function (log) {
    return process.env.NODE_ENV === 'production' ? function () {} : log;
  })(console.log);
};

export { Initializer, rewirteLog };
