import { defineStore } from "pinia";
import * as api from "@/api/index";

const useChatStore = defineStore("chat", {
  state: () => ({
    //学生id
    stuId: 0,
    // 比赛id
    raceId: 0,
    // 是否查看图片
    isLook: false,
    // 当前图片
    nowImg: "",
    // 当前图片索引
    nowImgIndex: 0,
    // 当前图片列表描述
    nowImgDes: "",
    // 生成图片缩略图
    imgsList: [],
    // 查看图片详情
    imgbList: [],
    //剩余提交次数
    subNum: 0,
    //ai允许最大生成次数
    subTotalNum: 10,
    //ai允许生成次数
    subAiNum: 10,
    //提交弹框类型
    modalType: "auto",
    //是否是自动交卷
    isAutoSub: false,
    // 参赛主题
    theme: "自然与科技和和谐共存",
    // 当前会话的回答记录
    chatData: [],
    // 所有会话的回答记录
    chatRecord: [],
    // 当前会话的索引
    nowIndex: 0,
    // 是否已经提交作品
    subState: false,
    // 最新生成的图片（自动交卷提交 - 预备）
    autoImg: "",
    // 提交的图片
    subImg: "",
  }),

  getters: {},
  actions: {
    set_stuId(stuId) {
      this.stuId = stuId;
    },
    set_raceId(raceId) {
      this.raceId = raceId;
    },
    set_isLook(isLook) {
      this.isLook = isLook;
    },
    set_nowImg(nowImg) {
      this.nowImg = nowImg;
    },
    set_nowImgIndex(nowImgIndex) {
      this.nowImgIndex = nowImgIndex;
    },
    set_nowImgDes(nowImgDes) {
      this.nowImgDes = nowImgDes;
    },

    set_imgsList(imgsList) {
      this.imgsList = imgsList;
    },
    set_imgbList(imgbList) {
      this.imgbList = imgbList;
    },
    set_subNum(subNum) {
      this.subNum = subNum;
    },
    set_subTotalNum(subTotalNum) {
      this.subTotalNum = subTotalNum;
    },
    set_subAiNum(subAiNum) {
      this.subAiNum = subAiNum;
    },
    set_modalType(modalType) {
      this.modalType = modalType;
    },
    set_isAutoSub(isAutoSub) {
      this.isAutoSub = isAutoSub;
    },
    set_theme(theme) {
      this.theme = theme;
    },
    set_chatData(chatData) {
      this.chatData = chatData;
    },
    set_chatRecord(chatRecord) {
      this.chatRecord = chatRecord;
    },
    set_nowIndex(nowIndex) {
      this.nowIndex = nowIndex;
    },
    set_subState(subState) {
      this.subState = subState;
    },
    set_autoImg(autoImg) {
      this.autoImg = autoImg;
    },
    set_subImg(subImg) {
      this.subImg = subImg;
    },
  },
  persist: {
    afterRestore: () => {
      console.log("set success");
    },
    key: "chat",
    paths: ["chatRecord", "nowIndex", "stuId", "raceId"],
  },
});

export default useChatStore;
